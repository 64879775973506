// ---------------
// REACT
// ---------------
import React from 'react';
import PropTypes from 'prop-types';

// ---------------
// DATA
// ---------------
import data from '../../content/data';

// ---------------
// COMPONENTS
// ---------------
import { Helmet } from 'react-helmet';
import { JsonLd } from 'react-schemaorg';


const SEO = ( { description, lang, meta, title } ) => (
	<>
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			meta={[
				{
					name: 'description',
					content: description,
				},
				{
					property: 'og:url',
					content: data.url,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: description,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${data.url}/og-meta.png`,
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: description,
				},
				{
					name: 'twitter:image',
					content: `${data.url}/og-meta.png`,
				},
			].concat( meta )}
		/>
		<JsonLd
			item={{
				'@context': 'http://schema.org',
				'@type': 'WebSite',
				'name': data.name,
				'url': data.url,
			}}/>
		<JsonLd
			item={{
				'@context': 'http://schema.org',
				'@type': 'Person',
				'hasOccupation': [
					{
						'@type': 'Occupation',
						'name': data.occupation,
						'occupationLocation': {
							'@type': 'City',
							'name': data.address.city,
						},
						'occupationalCategory': data.occupationalCategory,
					},
				],
				'name': data.name,
				'url': data.url,
				'sameAs': data.social.filter( item => item.show ).map( item => item.url ),
			}}/>
	</>
);

SEO.defaultProps = {
	lang: 'en',
	meta: [],
	description: data.description,
	title: data.name,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf( PropTypes.object ),
	title: PropTypes.string.isRequired,
};

export default SEO;
