// ---------------
// REACT
// ---------------
import React from 'react';

// ---------------
// DATA
// ---------------
import data from '../../content/data';

// ---------------
// COMPONENTS
// ---------------
import Layout from '../components/layout';
import SEO from '../components/seo';

// ---------------
// HELPERS
// ---------------
import { smartApos, buddySystem } from '../helpers/helpers';

// ---------------
// STYLES
// ---------------
import '../styles/fonts/font-face-home.css';
import styles from './index.module.scss';


const { projectsWorkedOn, skills } = data;

export default () => (
	<Layout>
		<SEO/>
		<div className={styles.experienceSection}>
			<div className={styles.experienceSectionInner}>
				<div className={styles.experienceSectionHeading}>
					<h1>{buddySystem( smartApos( projectsWorkedOn.heading ) )}</h1>
				</div>
				<div className={styles.experienceSectionContent}>
					{projectsWorkedOn.description ? (
						<p className={styles.experienceSectionContentText}>{buddySystem( smartApos( projectsWorkedOn.description ) )}</p>
					) : ''}
					{projectsWorkedOn.items.map( ( group, index ) => (
						<div key={index} className={styles.experienceSectionContentItem}>
							<h2>{buddySystem( smartApos( group.heading ) )}</h2>
							<ul>
								{group.projects.map( ( project, index ) => (
									project.show ? (
										<li key={index}>
											<a href={project.url} target="_blank" rel="noopener noreferrer">{buddySystem( smartApos( project.name ) )}</a>
										</li>
									) : ''
								) )}
							</ul>
						</div>
					) )}
				</div>
			</div>
		</div>
		<div className={styles.experienceSection}>
			<div className={styles.experienceSectionInner}>
				<div className={styles.experienceSectionHeading}>
					<h1>{buddySystem( smartApos( skills.heading ) )}</h1>
				</div>
				<div className={styles.experienceSectionContent}>
					{skills.description ? (
						<p className={styles.experienceSectionContentText}>{buddySystem( smartApos( skills.description ) )}</p>
					) : ''}
					{skills.items.map( ( group, index ) => (
						<div key={index} className={styles.experienceSectionContentItem}>
							<h2>{buddySystem( smartApos( group.heading ) )}</h2>
							<ul>
								{group.items.map( ( skill, index ) => (
									<li key={index}>{buddySystem( smartApos( skill ) )}</li>
								) )}
							</ul>
						</div>
					) )}
				</div>
			</div>
		</div>
	</Layout>
);
